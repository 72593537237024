
import { defineComponent, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import moment from "moment";
import { useFieldValue } from "vee-validate";
import { ElMessageBox } from "element-plus";

export default defineComponent({
  name: "create-record-modal",
  components: {
  },
  props: {
      event: {} as any,
      eventTypes:[],
      getEvents : {
        type:Function,
        default: () => ({}),
      },
  },
  emits: ['getEvents'],
  setup(props, {emit}) {
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const user = store.getters.currentUser;
    const eventData = ref<any>({});

    const rules = ref({  
        type: [
            {
                required: true,
                message: "Please select event type",
                trigger: "blur",
            },
        ],
        title: [
            {
                required: true,
                message: "Please enter the event title",
                trigger: "change",
            },
        ],
    });

    watch(() => props.event, ()=>{
        eventData.value = props.event;
        if(props.event.start_date){
            eventData.value.period = [];
            eventData.value.period[0] = props.event.start_date;
            eventData.value.period[1] = props.event.end_date;
        }
    })

    const editEvent = async (formData) => {
        ApiService.setHeader();
        if(eventData.value.id && eventData.value.id !==0)
        {
            ApiService.update("event", eventData.value.id, formData)
                .catch(({ response }) => {
                    loading.value = false;
                    console.log('edit event error => '+response.data.errors);
                    Swal.fire({
                    text: "Failed to edit event",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    });
                });
        }
        else
        {
            ApiService.post("event", formData)
                .catch(({ response }) => {
                    loading.value = false;
                    console.log('create event error => '+response.data.errors);
                    Swal.fire({
                    text: "Failed to create the new event",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    });
                });
        }
    }

    const submit = () => {
        if (!formRef.value) {
            return;
        }

        formRef.value.validate((valid) => {
            if (valid){
            loading.value = true;
            eventData.value.start_date = eventData.value.period[0];
            eventData.value.end_date = eventData.value.period[1];
            eventData.value.user_id = user.id;
            delete eventData.value.start;
            delete eventData.value.end;
            delete eventData.value.period;
            editEvent(eventData.value).then(() => {
                Swal.fire({
                text: "Form has been successfully submitted!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                }).then(() => {
                    loading.value = false;
                    let closeEditBtn = document.getElementById('close_event_button');
                    closeEditBtn?.click();
                    emit('getEvents');
                });
            });
        } else {
            Swal.fire({
                text: "Please fill out all required fields",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                confirmButton: "btn btn-primary",
                },
            });
            return false;
            }
        });
    };

    const deleteEvent = () => {
      ElMessageBox.confirm('Are you sure to delete this class?').then(() => {
        ApiService.setHeader();
        ApiService.delete("event/"+eventData.value.id)
          .then(() => {
            Swal.fire({
                text: "The event is deleted successfully",
                icon: "success",
                buttonsStyling: false,  
                confirmButtonText: "Ok, got it!",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                })
                .then(() => {
                    let closeEditBtn = document.getElementById('close_event_button');
                    closeEditBtn?.click();
                    emit('getEvents');
                });
          })
          .catch(({ response }) => {
            console.log('delete event error => '+response.data.errors);
            Swal.fire({
                text: "Failed to delete event",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                });
            });
          });
    };

    return {
      loading,
      formRef,
      moment,
      submit,
      eventData,
      rules,
      deleteEvent
    };
  },
});
