<template>
    <div class="d-flex flex-stack" style="padding-bottom:10px;">
        <div class="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
        </div>
        <div class="col-md-6" align="right">
        <div class="me-4">
            <a
                href="#"
                style="display:none;"
                data-bs-toggle="modal"
                data-bs-target="#modal_edit_event"
                id="event_edit_button"
            />
            <button class="btn btn-sm btn-primary" type="button" @click="openEditModal({})">
                Create
            </button>
        </div>
        </div>
    </div>
    <div class="card mb-5 mb-xl-10 p-10">
        <Calendar 
            v-bind:openEditModal="openEditModal"
            ref="calendarWidget"/>
    </div>
    <EditEvent
        v-bind:event="selectedEvent"
        v-bind:eventTypes="eventTypes"
        @getEvents="getEvents">
    </EditEvent>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Calendar from "@/views/widgets/Calendar.vue"
import EditEvent from   "@/views/management/modals/EditEvent.vue"
import ApiService from "@/core/services/ApiService";
import moment from "moment";

export default defineComponent({
    name: "calendar",
    components: {
        Calendar,
        EditEvent
    },
    setup() {
        const selectedEvent = ref({});
        const eventTypes = ref([]);
        const calendarWidget = ref<InstanceType<typeof Calendar>>()
        const getTypes = async() =>{
            ApiService.setHeader();
            await ApiService.get("event/types")
                    .then(({ data }) => {
                        eventTypes.value = data.eventTypes;
                    })
                    .catch(({ response }) => {
                    console.log('getTypes error => '+response.data.errors);
                    });
        }

        const openEditModal = (event) =>{
            selectedEvent.value = event;
            let editBtn = document.getElementById('event_edit_button');
            editBtn?.click();
        }

        const getEvents = () =>{
            calendarWidget.value?.getEvents();
        }

        onMounted(() => {
            getTypes();
            setCurrentPageBreadcrumbs("Calendar", ["Management"]);
        });

        return {
            selectedEvent,
            eventTypes,
            openEditModal,
            calendarWidget,
            getEvents
        }
    },
});
</script>