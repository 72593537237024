
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Calendar from "@/views/widgets/Calendar.vue"
import EditEvent from   "@/views/management/modals/EditEvent.vue"
import ApiService from "@/core/services/ApiService";
import moment from "moment";

export default defineComponent({
    name: "calendar",
    components: {
        Calendar,
        EditEvent
    },
    setup() {
        const selectedEvent = ref({});
        const eventTypes = ref([]);
        const calendarWidget = ref<InstanceType<typeof Calendar>>()
        const getTypes = async() =>{
            ApiService.setHeader();
            await ApiService.get("event/types")
                    .then(({ data }) => {
                        eventTypes.value = data.eventTypes;
                    })
                    .catch(({ response }) => {
                    console.log('getTypes error => '+response.data.errors);
                    });
        }

        const openEditModal = (event) =>{
            selectedEvent.value = event;
            let editBtn = document.getElementById('event_edit_button');
            editBtn?.click();
        }

        const getEvents = () =>{
            calendarWidget.value?.getEvents();
        }

        onMounted(() => {
            getTypes();
            setCurrentPageBreadcrumbs("Calendar", ["Management"]);
        });

        return {
            selectedEvent,
            eventTypes,
            openEditModal,
            calendarWidget,
            getEvents
        }
    },
});
